<template>
  <div class="content-detail-page">
    <!-- 썸네일 이미지가 있는 경우 -->
    <div v-if="contentItem.thumbnails && contentItem.thumbnails.length" class="content-image">
      <img
        v-for="thumbnail in contentItem.thumbnails"
        :key="thumbnail"
        :src="thumbnail"
        :alt="contentItem.altText"
      />
    </div>
    <div class="content-text">
      <h4 v-if="contentItem.title">{{ contentItem.title }}</h4>
      <!-- v-html 사용 시, 데이터가 안전한지 확인 -->
      <div v-html="contentItem.description"></div>
      <span v-if="contentItem.year">{{ contentItem.year }}</span>
    </div>
    <div class="go-back-container">
      <h5 class="go-back-button" @click="goBack">돌아가기</h5>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import imagesData from '@/data/images.js';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const workId = route.params.workId;
    const contentId = parseInt(route.params.contentId, 10);

    // imagesData에서 해당 workId에 맞는 데이터를 가져오되, 기본값으로 빈 배열들을 할당
    const workData = computed(() => {
      return imagesData[workId] || { images: [], content: [], combinedContent: [] };
    });

    // contentItem 추출, 없는 경우 빈 객체 반환
    const contentItem = computed(() => {
      const contents = workData.value.combinedContent || [];
      const item = contents[contentId] || {};
      // alt 텍스트가 별도로 없다면, description의 일부를 기본 alt로 사용 (필요에 따라 수정)
      return {
        ...item,
        altText: item.altText || (item.description ? item.description.slice(0, 50) : 'No description available')
      };
    });

    // 뒤로가기 함수
    const goBack = () => {
      router.back();
    };

    return {
      contentItem,
      goBack
    };
  }
};
</script>

<style scoped>
.content-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.content-image {
  display: grid;
  gap: 10px;
  max-width: 500px;
  margin-bottom: 20px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.content-image img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

/* 하나의 이미지인 경우 */
.content-image img:nth-child(1):only-child {
  width: 500px;
}

/* 두 개 이상의 이미지 처리 */
.content-image img:nth-child(-n+2):only-of-type,
.content-image img:nth-child(3):nth-last-child(1) {
  width: 240px;
}

/* 세 번째 혹은 다섯 번째 이미지가 마지막일 경우 전체 폭 사용 */
.content-image img:nth-child(3):last-child,
.content-image img:nth-child(5):last-child {
  width: 500px;
  grid-column: 1 / -1;
}

.content-text {
  max-width: 500px;
  margin-bottom: 20px;
}

/* 반응형 스타일 */
@media (max-width: 500px) {
  .content-image {
    grid-template-columns: 1fr;
  }
  .content-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .content-text {
    width: 100%;
    max-width: 100%;
  }
}

.go-back-container {
  text-align: center;
  margin-top: 10px;
}

.go-back-button {
  font-weight: 400;
  cursor: pointer;
  border-bottom: 1px solid #cfff00;
  display: inline-block;
  padding: 5px;
}

.go-back-button:hover {
  opacity: 0.8;
}
</style>