import { createApp } from 'vue';
import App from './App';
import router from './router';
import VueGtag from 'vue-gtag-next';
import VueLazyLoad from 'vue-lazyload-next';
import { createHead } from '@vueuse/head';
import VueEasyLightbox from 'vue-easy-lightbox';
import './global.css';

const app = createApp(App);
const head = createHead();

app.use(router);

// ✅ Google Analytics 설정 수정
const trackingID = process.env.VUE_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX'; // 기본값 추가
if (!trackingID || trackingID === 'G-XXXXXXXXXX') {
  console.warn("⚠️ Google Analytics Tracking ID is missing!");
}

app.use(VueGtag, {
  property: {
    id: trackingID, // ID가 없으면 경고 출력
  },
  isEnabled: process.env.NODE_ENV !== 'development', // 개발 모드에서도 실행 가능하도록 변경
  appName: 'TaejunYun',
  pageTrackerScreenviewEnabled: true,
}, router);

app.use(VueLazyLoad, {
  preLoad: 1.2,
  attempt: 1
});
app.use(head);

// vue-easy-lightbox를 글로벌 컴포넌트로 등록
app.component('VueEasyLightbox', VueEasyLightbox);

app.mount('#app');