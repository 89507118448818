import { createRouter, createWebHistory } from 'vue-router';

// 페이지 컴포넌트 정적 임포트
import MainView from '@/components/MainView.vue';
import CvCompView from '@/components/CvCompView.vue';
import ArtStateView from '@/components/ArtStateView.vue';
import ImageCompView from '@/components/ImageCompView.vue';
import AproComp from '@/components/project/AproComp.vue';
import NetworkGalleryView from '@/components/work/NetworkGalleryView.vue';
import ContentDetailView from '@/components/work/ContentDetailView.vue';

// 기본 SEO 값 (index.html에 작성한 기본 메타 정보)
const DEFAULT_TITLE = '윤태준 | Taejun Yun';
const DEFAULT_DESCRIPTION = '윤태준, 현대미술 사진작가. 서울과 광주를 기반으로 활동하며 디지털 매체와 사진의 경계를 탐구합니다. 그의 작품은 현대 사진 예술의 가능성을 실험하며, 감각적 지각과 재현의 한계를 표현합니다.';
const DEFAULT_KEYWORDS = '윤태준, 현대미술, 사진작가, 사진 매체, contemporary art, visual artist, art photography';
const BASE_URL = 'https://www.taejunyun.com';

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView,
    meta: {
      title: '윤태준 | Taejun Yun',
      description: DEFAULT_DESCRIPTION,
      keywords: DEFAULT_KEYWORDS
    }
  },
  {
    path: '/about',
    name: 'CvCompView',
    component: CvCompView,
    meta: {
      title: 'CV - 윤태준',
      description: '이력 및 자기소개 정보를 확인하실 수 있습니다.',
      keywords: '이력, 자기소개'
    }
  },
  {
    path: '/text',
    name: 'ArtStateView',
    component: ArtStateView,
    meta: {
      title: 'Text - 윤태준',
      description: '텍스트 기반 작품 및 에세이 정보를 제공합니다.',
      keywords: '텍스트, 작품, 에세이'
    }
  },
  {
    path: '/work/:workId',
    name: 'ImageCompView',
    component: ImageCompView,
    props: true,
    meta: {
      title: 'Works - 윤태준',
      description: '작품 갤러리를 확인하실 수 있습니다.',
      keywords: '작품, 갤러리'
    }
  },
  {
    path: '/project',
    name: 'AproComp',
    component: AproComp,
    meta: {
      title: 'Installation & etc - 윤태준',
      description: '설치 미술 및 기타 프로젝트 정보를 제공합니다.',
      keywords: '설치, 프로젝트'
    }
  },
  {
    path: '/research/:workId',
    name: 'NetworkGalleryView',
    component: NetworkGalleryView,
    props: true,
    meta: {
      title: 'Networks Research - 윤태준',
      description: '네트워크 기반 연구 전시를 확인하실 수 있습니다.',
      keywords: '연구, 네트워크'
    }
  },
  {
    path: '/research/:workId/content/:contentId',
    name: 'ContentDetailView',
    component: ContentDetailView,
    props: true,
    meta: {
      title: 'Research - 윤태준',
      description: '상세 콘텐츠 및 연구 자료를 제공합니다.',
      keywords: '연구, 상세 콘텐츠'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }; // 페이지 이동 시 스크롤 상단으로 초기화
  }
});

// 페이지 이동 후 SEO 메타 태그 업데이트
router.afterEach((to) => {
  // 1. 문서 타이틀 업데이트
  const title = to.meta.title || DEFAULT_TITLE;
  document.title = title;

  // 2. 메타 디스크립션 업데이트
  const description = to.meta.description || DEFAULT_DESCRIPTION;
  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  } else {
    metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', description);
    document.head.appendChild(metaDescription);
  }

  // 3. 메타 키워드 업데이트
  const keywords = to.meta.keywords || DEFAULT_KEYWORDS;
  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords);
  } else {
    metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', keywords);
    document.head.appendChild(metaKeywords);
  }

  // 4. Canonical URL 업데이트
  const canonicalHref = BASE_URL + to.fullPath;
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (canonicalLink) {
    canonicalLink.setAttribute('href', canonicalHref);
  } else {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', canonicalHref);
    document.head.appendChild(canonicalLink);
  }

  // 5. Open Graph 태그 업데이트: og:title, og:description, og:url
  let ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content', title);
  } else {
    ogTitle = document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
    ogTitle.setAttribute('content', title);
    document.head.appendChild(ogTitle);
  }

  let ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute('content', description);
  } else {
    ogDescription = document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.setAttribute('content', description);
    document.head.appendChild(ogDescription);
  }

  let ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute('content', canonicalHref);
  } else {
    ogUrl = document.createElement('meta');
    ogUrl.setAttribute('property', 'og:url');
    ogUrl.setAttribute('content', canonicalHref);
    document.head.appendChild(ogUrl);
  }

  // 6. Twitter Card 태그 업데이트: twitter:title, twitter:description
  let twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute('content', title);
  } else {
    twitterTitle = document.createElement('meta');
    twitterTitle.setAttribute('name', 'twitter:title');
    twitterTitle.setAttribute('content', title);
    document.head.appendChild(twitterTitle);
  }

  let twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute('content', description);
  } else {
    twitterDescription = document.createElement('meta');
    twitterDescription.setAttribute('name', 'twitter:description');
    twitterDescription.setAttribute('content', description);
    document.head.appendChild(twitterDescription);
  }
});

export default router;