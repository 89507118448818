<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <div class="menu-wrap">
      <div class="home-link-container">
        <router-link class="home-link" to="/" style="color: #cfff00;">Yuntaejun</router-link>
        <span class="blinking-dot"></span>
      </div>
      <nav class="sub-wrap">
        <div @click="toggleMenu('works')" class="menu-title no-dark-mode">
          Works <span>{{ isActive('works') ? '[-]' : '[+]' }}</span>
        </div>
        <div v-show="isActive('works')" class="sub-menu">
          <router-link 
            v-for="work in works" 
            :key="work.id" 
            @click="closeMenu" 
            class="link" 
            :to="work.path">
            <h6>{{ work.title }}</h6>
          </router-link>
        </div>
        <div>
          <router-link @click="closeMenu" class="menu-title" to="/project">Install & Etc</router-link>
        </div>
        <div>
          <router-link class="menu-title" to="/text">Text</router-link>
        </div>
        <div>
          <router-link class="menu-title" to="/about">C.V</router-link>
        </div>
      </nav>
      <button @click="toggleDarkMode" class="dark-mode-toggle">
        <span>{{ isDarkMode ? '🌙' : '☀️' }}</span>
      </button>
    </div>
    <div class="router-view">
      <router-view :key="$route.fullPath" />
      <div class="footer">
        <span>©2023. All page content is property of Taejun Yun</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

// works 목록
const works = [
  { id: 'Transmitter', title: 'Transmitter to the object, 2025~(Ongoing)', path: '/work/Trans' },
  { id: 'Waterphoto', title: 'Water Photo Automat, 2024', path: '/work/Waterphoto' },
  { id: 'Ascent', title: '상승 Ascent, 2022', path: '/work/Ascent' },
  { id: 'Firefly', title: '반딧불이 Firefly, 2021', path: '/work/Firefly' },
  { id: 'Middleturn', title: '미들턴 Middle turn, 2020~2022', path: '/work/Middleturn' },
  { id: 'Low', title: '낮고, 빠르게 쏘기 Low, Quickdraw, 2019~2020', path: '/work/Low' },
  { id: 'Illusion', title: '환상계단 Illusion Stair, 2017', path: '/work/Illusion' },
  { id: 'Signal', title: '사이신호 Between Signal, 2016', path: '/work/Signal' },
  { id: 'Weight', title: '회한의무게 Weight of remorse, 2015', path: '/work/Weight' }
];

// 반응형 상태 변수
const activeMenus = ref([]);
const isDarkMode = ref(false);
const route = useRoute();

// 다크모드 상태를 로컬 스토리지에서 불러오기 및 초기 스크롤 처리
onMounted(() => {
  const savedDarkMode = localStorage.getItem('dark-mode');
  if (savedDarkMode !== null) {
    isDarkMode.value = savedDarkMode === 'true';
  }
  if (route.path.startsWith('/work')) {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }
});

// 경로 변경 시 /work 페이지면 스크롤 초기화
watch(
  () => route.path,
  (newPath) => {
    if (newPath.startsWith('/work')) {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  }
);

// 메뉴 토글 로직
const toggleMenu = (menu) => {
  const index = activeMenus.value.indexOf(menu);
  if (index > -1) {
    activeMenus.value.splice(index, 1);
  } else {
    activeMenus.value.push(menu);
  }
};

const isActive = (menu) => activeMenus.value.includes(menu);
const closeMenu = () => {
  activeMenus.value = [];
};

// 다크모드 토글 및 상태 저장
const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
  localStorage.setItem('dark-mode', isDarkMode.value);
};
</script>

<style scoped>
/* 다크 모드 스타일 */
#app.dark-mode {
  color: #f0f0f0;
}

.menu-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 0;
  color: #333333;
}

.home-link-container {
  display: flex;
  align-items: center;
}

.sub-wrap {
  display: flex;
  width: 65%;
  justify-content: space-between;
}

.router-view {
  margin-top: 1.5em;
  z-index: 0;
  position: relative;
  width: 100%;
}

/* Footer */
.footer {
  text-align: right;
}

/* Menu Styles */
.menu-title {
  cursor: pointer;
  position: relative;
}

/* 다크 모드 영향을 받지 않는 스타일 */
.no-dark-mode {
  color: #333333;
  background-color: inherit;
}

.sub-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3em;
  left: 1em;
  color: white;
  border: 1px dashed #cfff00;
  z-index: 1000;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  line-height: 2;
}

.sub-menu h6 {
  color: white;
}

/* Blinking Dot */
.blinking-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #cfff00;
  border-radius: 50%;
  margin-left: 3px;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* 다크 모드 토글 버튼 */
.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.dark-mode-toggle span {
  display: flex;
  align-items: center;
}

#app.dark-mode .dark-mode-toggle span {
  color: #f0f0f0;
}

/* Media Queries */
@media screen and (min-width: 320px) and (max-width: 768px) {
  .sub-menu {
    top: 3em;
    left: 1em;
  }
  .menu-wrap {
    font-size: 14px;
  }
}
</style>