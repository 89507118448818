<template>
  <div class="container">
    <div 
      v-for="(episode, index) in episodesToDisplay" 
      :key="episode.id || index"
      class="img-wrap"
      :style="getDelayStyle(index)"
    >
      <!-- 이미지 렌더링 -->
      <img 
        v-if="episode.type === 'image'"
        ref="images"
        class="responsive-media"
        :src="placeholderImage"
        :data-src="episode.thumbnail"
        :alt="episode.description"
        @error="setDefaultImage"
        @click="openLightbox(index)"
      />
      
      <!-- 비디오 렌더링 -->
      <div v-else-if="episode.type === 'video'" class="video-wrapper">
        <iframe 
          v-if="episode.videoLink"
          class="responsive-media"
          :src="convertYoutubeLink(episode.videoLink)"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <span class="video-link" v-if="episode.videoLink">
          <a :href="episode.videoLink" target="_blank" rel="noopener noreferrer">
            Watch Video
          </a>
        </span>
      </div>
      
      <!-- 설명 텍스트 -->
      <span v-if="episode.description" class="description">{{ episode.description }}</span>
    </div>
    
    <!-- Vue-Easy-Lightbox 통합 -->
    <vue-easy-lightbox
      v-model:visible="lightboxVisible"
      :imgs="imgList"
      :index="currentIndex"
      @hide="lightboxVisible = false"
    />
  </div>
</template>

<script>
import placeholderImage from '@/assets/placeholder-image.png';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'RecentCompView',
  props: {
    episodes: {
      type: Array,
      required: true
    },
    shuffle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      placeholderImage,
      episodesToDisplay: [],
      lightboxVisible: false,
      currentIndex: 0
    };
  },
  created() {
    this.prepareEpisodes();
  },
  mounted() {
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  computed: {
    // Vue-Easy-Lightbox에 전달할 이미지 URL 배열 (이미지 타입만 필터링)
    imgList() {
      return this.episodesToDisplay
        .filter(ep => ep.type === 'image')
        .map(ep => ep.thumbnail);
    }
  },
  methods: {
    // 에피소드 배열 복사 후, 필요 시 랜덤 섞기
    prepareEpisodes() {
      const copied = [...this.episodes];
      this.episodesToDisplay = this.shuffle
        ? copied.sort(() => Math.random() - 0.5)
        : copied;
    },
    // 이미지 로드 실패 시 기본 이미지 적용
    setDefaultImage(event) {
      event.target.src = this.placeholderImage;
      event.target.classList.add('error-image');
    },
    // 유튜브 링크를 embed 형식으로 변환
    convertYoutubeLink(link) {
      if (!link || typeof link !== 'string') return '';
      return link.includes('watch?v=')
        ? link.replace('watch?v=', 'embed/')
        : link;
    },
    // 애니메이션 지연 시간 계산 (index 기준)
    getDelayStyle(index) {
      return { animationDelay: `${index * 100}ms` };
    },
    // IntersectionObserver 초기화 (이미지 lazy-loading)
    initIntersectionObserver() {
      const observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      });
      // $refs.images가 단일 요소일 수 있으므로 배열로 보장
      const images = this.$refs.images;
      const imagesArray = Array.isArray(images) ? images : [images];
      imagesArray.forEach(img => {
        if (img && img.tagName === 'IMG') {
          observer.observe(img);
        }
      });
    },
    // 관찰 대상이 뷰포트에 진입하면 고해상도 이미지 로드
    handleIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target.tagName === 'IMG') {
          this.loadHighResImage(entry.target, observer);
        }
      });
    },
    // 고해상도 이미지 로드 후 적용 및 관찰 해제
    loadHighResImage(img, observer) {
      const highResSrc = img.getAttribute('data-src');
      if (!highResSrc) return;
      const tempImg = new Image();
      tempImg.src = highResSrc;
      tempImg.onload = () => {
        img.src = highResSrc;
        img.classList.add('loaded');
        // 부모 컨테이너의 aspect-ratio를 자연비율로 설정
        img.parentElement.style.aspectRatio = `${tempImg.naturalWidth} / ${tempImg.naturalHeight}`;
        observer.unobserve(img);
      };
      tempImg.onerror = () => {
        this.setDefaultImage({ target: img });
        observer.unobserve(img);
      };
    },
    // 이미지 클릭 시 Vue-Easy-Lightbox 열기
    openLightbox(index) {
      this.currentIndex = index;
      this.lightboxVisible = true;
    }
  },
  components: {
    VueEasyLightbox
  }
};
</script>

<style scoped>
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 5px;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  width: 100%;
  aspect-ratio: 4 / 3;
  cursor: pointer;
}
.vel-toolbar {
   display: none;;
}
.responsive-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: opacity 0.5s ease;
}

.responsive-media.loaded {
  opacity: 1;
}

.video-link {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .container {
    grid-template-columns: 1fr;
  }
}
</style>