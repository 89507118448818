<template>
  <div class="main-page-container">
    <div class="menu-item">
      <a 
        href="https://drive.google.com/file/d/1FKPvhg1uDHDEkxBBNY7NFE3FobgCWk7n/view?usp=drive_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Portfolio Download
      </a>
    </div>
    <div class="menu-item"> 
      <router-link class="menu-title network-link" to="/research/network">
        이미지 연구-네트워크, Image Research, Network
      </router-link>
    </div>
    <h5>Recent Work</h5>
    <RecentCompView :episodes="episodes" />
  </div>
</template>

<script>
import { ref } from 'vue';
import RecentCompView from '@/components/RecentCompView.vue';
import { imagesData } from '@/data/images.js';

export default {
  name: 'MainView',
  components: { RecentCompView },
  setup() {
    // imagesData.Recent가 없을 경우를 대비해 기본값을 설정합니다.
    const episodes = ref((imagesData.Recent && imagesData.Recent.images) || []);
    return { episodes };
  }
};
</script>

<style scoped>
.main-page-container {
  margin: 0;
}

.menu-item {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

/* router-link에 적용할 스타일을 별도 클래스에 정의 */
.network-link {
  border-bottom: 1px dashed #cfff00;
  color: #cfff00;
  font-size: 14px;
  text-decoration: none;
}

/* 필요 시 추가 스타일 */
.container-wrap {
  display: flex;
  flex-direction: column;
}
.front-img {
  width: 100%;
  max-width: 500px;
  min-width: 320px;
}
</style>