<template>
  <div class="gallery-page">
    <div class="work-title">
      <h5>{{ episodesData.title }}</h5>
    </div>
    
    <!-- 이미지 작업 영역 (.image-section) -->
    <div class="image-section" ref="containerRef" @wheel.prevent="handleScroll">
      <div class="gallery-container">
        <div class="gallery-wrapper">
          <div
            class="img-wrap"
            v-for="(episode, index) in imageWorks"
            :key="episode.thumbnail || index"
          >
            <img
              v-if="episode.type === 'image'"
              :src="episode.thumbnail"
              :alt="`Image ${index + 1}: ${episode.description}`"
              class="responsive-image fade-in"
              @error="setDefaultImage"
              @click="openLightbox(episode)"
            />
          </div>
        </div>
      </div>
    </div>
    
    <!-- 비디오 작업 영역 (.video-section) -->
    <div class="video-section">
      <div 
        class="video-wrapper"
        v-for="(episode, index) in videoWorks"
        :key="episode.videoLink || index"
      >
        <iframe
          :src="`https://www.youtube.com/embed/${extractYouTubeId(episode.videoLink)}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="responsive-video"
        ></iframe>
      </div>
    </div>
    
    <!-- 텍스트 작업 영역 (.text-section) -->
    <div class="text-section">
      <h5>작가노트 Artist's statement</h5>
      <div v-html="episodesData.statement"></div>
    </div>
    
    <!-- Vue-Easy-Lightbox (이미지 확대) -->
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="imgList"
      :index="currentIndex"
      @hide="lightboxVisible = false"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import { imagesData } from '@/data/images.js';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'ImageCompView',
  components: {
    VueEasyLightbox,
  },
  setup() {
    const route = useRoute();
    const containerRef = ref(null);

    // 에피소드 데이터 추출 (존재하지 않을 경우 기본 메시지 제공)
    const episodesData = computed(() => {
      const data = imagesData[route.params.workId];
      if (data) {
        return data;
      } else {
        console.error(`No data found for workId: ${route.params.workId}`);
        return {
          images: [],
          title: '등록된 제목이 없습니다.',
          statement: '등록된 아티스트 스테이트먼트가 없습니다.',
        };
      }
    });

    // 이미지 작업과 비디오 작업을 분리하는 computed
    const imageWorks = computed(() => {
      return episodesData.value.images.filter(episode => episode.type === 'image');
    });
    const videoWorks = computed(() => {
      return episodesData.value.images.filter(episode => episode.type === 'video');
    });

    // lightbox에 사용할 이미지 목록 (이미지 작업만 사용)
    const imgList = computed(() => {
      return imageWorks.value.map(episode => episode.thumbnail);
    });

    // 스크롤 초기화 함수
    const resetScroll = () => {
      if (containerRef.value) {
        containerRef.value.scrollLeft = 0;
        containerRef.value.scrollTop = 0;
      }
    };

    // 컴포넌트 마운트 시 스크롤 초기화
    onMounted(() => {
      resetScroll();
    });

    // 라우트 변경 시 스크롤 초기화
    onBeforeRouteUpdate((to, from, next) => {
      resetScroll();
      next();
    });

    // YouTube 비디오 ID 추출 함수
    const extractYouTubeId = (url) => {
      const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/))([\w-]{11})/);
      return match ? match[1] : '';
    };

    // 이미지 로드 실패 시 기본 이미지 설정
    const setDefaultImage = (event) => {
      event.target.src = require('@/assets/placeholder-image.png');
      event.target.classList.add('error-image');
    };

    // 좌우 스크롤 이벤트 처리 (스크롤 속도 조정)
    const handleScroll = (event) => {
      event.preventDefault();
      const container = event.currentTarget;
      container.scrollLeft += event.deltaY * 1.2;
    };

    // Vue-Easy-Lightbox 관련 상태 및 함수
    const lightboxVisible = ref(false);
    const currentIndex = ref(0);
    const openLightbox = (episode) => {
      console.log('이미지 클릭됨:', episode);
      const index = imgList.value.indexOf(episode.thumbnail);
      if (index !== -1) {
        currentIndex.value = index;
        lightboxVisible.value = true;
        console.log('라이트박스 열림, 인덱스:', index);
      } else {
        console.warn('해당 이미지의 인덱스를 찾지 못했습니다.');
      }
    };

    return {
      containerRef,
      episodesData,
      extractYouTubeId,
      setDefaultImage,
      handleScroll,
      lightboxVisible,
      currentIndex,
      imgList,
      openLightbox,
      imageWorks,
      videoWorks,
    };
  },
};
</script>

<style scoped>
/* 기본 레이아웃 */
.gallery-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

/* 이미지 작업 영역 스타일 */
.image-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(300px, 550px);
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  max-height: 100vh;
  position: relative;
  margin-top: 10px;
  width: 100%;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

/* 갤러리 래퍼 */
.gallery-wrapper {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

.img-wrap {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
}

/* 이미지 스타일 */
.responsive-image {
  width: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* 비디오 작업 영역 스타일 */
.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper {
  width: 100%;
  max-width: 100%;
  height: 500px;
}

/* 비디오 iframe 스타일 */
.responsive-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}

/* 텍스트 작업 영역 스타일 */
.text-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  border-left: 2px dashed #cfff00;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .image-section {
    grid-auto-columns: minmax(300px, 400px);
    gap: 5px;
  }
  .video-wrapper {
    height: 400px;
  }
  .responsive-image {
    max-height: 400px;
  }
}

@media (min-width: 1200px) {
  .image-section {
    grid-auto-columns: minmax(400px, 550px);
    gap: 15px;
  }
}

@media (max-width: 1200px) {
  .gallery-page {
    flex-direction: column;
  }
  .img-wrap img {
    display: block;
    width: 550px;
    object-fit: contain;
  }
  .text-section {
    width: 100%;
  }
}
</style>